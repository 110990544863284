import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

import es from '../locales/es.ts'
const messages = {
    es: {
        $vuetify: {
            dataIterator: {
                rowsPerPageText: 'Elementos por página:',
                // pageText: '{0}-{1} de {2}'
            },
            dataFooter: {
                itemsPerPageText: 'Elementos por página:',
                itemsPerPageAll: 'Todos',
                nextPage: 'Siguiente página',
                prevPage: 'Página anterior',
                firstPage: 'Primera página',
                lastPage: 'Última página',
            }
        },
    }
}


export default new Vuetify({
    lang: {
        locales: { es },
        current: 'es',
        messages
    },
    theme: {
        themes: {
            light: {
                primary: '#a9c320',
                secondary: '#363636',
                error: '#b71c1c',
            },
        },
    },
});