import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        instanciaPhone: "",
        registro: "",
        llamadaEstado: "",
    },
    mutations: {
        SET_INSTANCIAPHONE(state, instanciaPhone) {
            state.instanciaPhone = instanciaPhone;
        },
        SET_REGISTRO(state, registro) {
            state.registro = registro;
        },
        SET_LLAMADAESTADO(state, llamadaEstado) {
            state.llamadaEstado = llamadaEstado;
        },
    },
    getters: {
        getinstanciaPhone(state) {
            return state.instanciaPhone
        },
        getregistro(state) {
            return state.registro
        },
        getllamadaEstado(state) {
            return state.llamadaEstado
        },
    },
    actions: {
        setinstanciaPhone(context, instanciaPhone) {
            context.commit('SET_INSTANCIAPHONE', instanciaPhone)
        },
        setregistro(context, registro) {
            context.commit('SET_REGISTRO', registro)
        },
        setllamadaEstado(context, llamadaEstado) {
            context.commit('SET_LLAMADAESTADO', llamadaEstado)
        },
    },
})
