export default {
    close: 'Cerrar',
    dataIterator: {
        pageText: 'de',
        noResultsText: 'No se encontraron registros coincidentes',
        loadingText: 'Cargando datos...',
    },
    dataTable: {
        itemsPerPageText: 'Filas por página:',
        ariaLabel: {
            sortDescending: ':Ordenado en orden descendente. Activar para eliminar el filtro.',
            sortAscending: ': Ordenado en orden ascendente. Activar para eliminar el filtro.',
            sortNone: ': No ordenado. Activar para ordenar ascendentemente.',
            sortBy: 'ordenador por',
            activateAscending: '',
            activateDescending: ''
        },
    },
    dataFooter: {
        itemsPerPageText: 'Elementos por página:',
        itemsPerPageAll: 'Todos',
        nextPage: 'Página siguiente',
        prevPage: 'Página anterior',
        firstPage: 'Primera página',
        lastPage: 'Última página',
        pageText: 'de'
    },
    datePicker: {
        itemsSelected: '{0} seleccionado',
    },
    noDataText: 'Datos no disponibles.',
    carousel: {
        prev: 'Previous visual',
        next: 'Next visual',
    },
    calendar: {
        moreEvents: '{0} más',
    },
  }
