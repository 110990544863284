import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            component: () => import('./views/dashboard/Index'),
            children: [
                {
                    name: 'Phone',
                    path: '',
                    component: () => import('@/views/dashboard/Phone'),
                },
            ],
        },
    ]

})